import * as React from "react"
import { Helmet } from "react-helmet";

import { Navigation, Content, Banner } from "../components";
import "../styles/main.scss";
import calendar from "../images/calendar.png";

import flag from "../images/icons/flag.svg";
import people from "../images/icons/people.svg";
import map from "../images/icons/map.svg";

// --------------------------------------------------------------------------------
// BANNER
// --------------------------------------------------------------------------------
function PageBanner(): JSX.Element {
    const title = <>Strategic workforce insights for senior leaders</>;

    const subtitle = <>
        <p>Identify your organsation's strengths and weaknesses.<br />
            Inform organisation change with clear reports.<br />
            Track the delivery of change programmes.</p>
    </>;

    // const sparkle = <img className="w-100 h-100 border ps-2 py-2 bg-white" src={calendar} width="417px" height="288px" alt="" aria-label="" />;
    const sparkle = <div className="text-md-end">
        <img src={calendar} alt="Sample calender view" className="bg-white border mw-100 xmh-100" />
    </div>;

    return <Banner
        title={title}
        subtitle={subtitle}
        action={{ to: "/contact-us/", caption: "Request a demo" }}
        sparkle={sparkle}
    />;
}


// --------------------------------------------------------------------------------
// Introduction
// --------------------------------------------------------------------------------
function PageIntro(): JSX.Element {
    const intro = <>
        <h2>Your strategic HR analytics platform</h2>
        <p>
            Uncover insights from your existing data with the tools and skills your people already have.
        </p>
        <p>
            Leverage the investments you've already made to drive marginal gains and deliver transformation programmes.
        </p>
    </>;

    return <div className="row row-cols-1 row-cols-sm-1 pb-4 gy-4">
        <div className="col text-sm-center px-4 ">
            {intro}
        </div>
    </div>;
}


// --------------------------------------------------------------------------------
// Body
// --------------------------------------------------------------------------------
function PageBody(): JSX.Element {
    const body1 = <>
        <h2>See the bigger picture</h2>
        <p>We’ve included an array of pre-built dashboards for HR business partners and leaders.</p>
        <p>Each dashboard focuses on a specific HR topic to help you identify what’s happening across your organisation. No matter which population you are reviewing.</p>
        <p>In no time at all you’ll be drilling into your data, using people analytics and delivering real HR insight to business owners.</p>
    </>;

    const body2 = <>
        <h2>Guide the transformation</h2>
        <p>Create a single source of truth for everything HR related within your organisation.</p>
        <p>We’ve made it easy for you to combine data from HR, finance and operations systems without the need for IT support. Better still you don’t need to be a data scientist to start conducting complex HR data analysis.</p>
        <p>HR metrics, scientific calculations and graphs are included in our platform to help you quickly deliver real insight about your people.</p>
    </>;

    return <div className="row row-cols-1 row-cols-sm-2 pb-4 gy-4">
        <div className="col px-4">
            {body1}
        </div>
        <div className="col px-4">
            {body2}
        </div>
    </div>;
}


// --------------------------------------------------------------------------------
// Features
// --------------------------------------------------------------------------------
function PageFeatures(): JSX.Element {
    const block1 = <>
        <div className="px-0 py-4">
            <img src={people} className="my-0 mb-4" width="100%" height="40x" alt="" aria-label="Home" />

            <h2>Ready-to-use</h2>
            <ul>
                <li>In-built HR dashboards and reports</li>
                <li>Pre-calculated HR metrics and analytics</li>
                <li>Library of graphs and chart types</li>
            </ul>
        </div>
    </>;

    const block2 =
        <div className="px-0 py-4">
            <img src={flag} className="my-0 mb-4" width="100%" height="40x" alt="" aria-label="Home" />
            <h2>Powerful insight</h2>
            <ul>
                <li>Tailor insight to audience</li>
                <li>Quickly respond to data requests</li>
                <li>Highlight issues and target resolution </li>
            </ul>
        </div>;

    const block3 = <>
        <div className="px-0 py-4">
            <img src={map} className="my-0 mb-4" width="100%" height="40x" alt="" aria-label="Home" />

            <h2>Fully configurable</h2>
            <ul>
                <li>Customise and tailor dashboards</li>
                <li>Build and create new reports </li>
                <li>Brand and publish stunning reports</li>
            </ul>
        </div>
    </>;

    return <div className="row row-cols-1 row-cols-sm-3">
        <div className="col qbl-block-1 px-4">
            {block1}
        </div>
        <div className="col qbl-block-2 px-4">
            {block2}
        </div>
        <div className="col qbl-block-1 px-4">
            {block3}
        </div>
    </div>;
}

//

const PeopleAnalytics = () =>
    <>
        <Helmet title="Qebal HR Executive Dashboards" htmlAttributes={{ lang: 'en' }} />
        <Navigation />
        <Content>
            <div className="container-lg">
                <PageBanner />
                <PageIntro />
                <PageBody />
                <PageFeatures />
            </div>
        </Content>
    </>;

export default PeopleAnalytics;